import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Step, StepLabel, Stepper } from '@material-ui/core'
import React, { ReactElement, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ObjectSchema, boolean, mixed, number, object, string } from 'yup'

import {
  AssociationDetailsForm,
  AssociationDetailsFormProps,
} from './association-details-form'

import { PrimaryContactForm } from './primary-contact-form'
import { SecondaryContactForm } from './secondary-contact-form'
import { Success } from './success'
import {
  Association,
  useAddAssociationMutation,
} from '../../services/api/endpoints/associations'
import { Address } from '../../services/api/endpoints/sub-masters'
import {
  AssociationLogoForm,
  AssociationLogoFormProps,
} from './association-logo-form'

export type FormInput = {
  abn: string
  name: string
  address: Address
  logo: any
  gstRegistered: boolean
  school: boolean
  status: string
  subMaster: null
  primaryContact: {
    name: string
    email: string
    phone: string
  }
  secondaryContact: {
    name: string
    email: string
    phone: string
  }
  agreedToTruth: boolean
  agreedToPolicies: boolean
}

type TStep = {
  actionLabel: string
  renderComponent: (props: any) => ReactElement
  defaultValues: Partial<FormInput>
  name: string
  optional?: boolean
  validationSchema: ObjectSchema<any>
}

const AssociationRegistrationForm = () => {
  const [abnLookupComplete, setAbnLookupComplete] = useState(false)
  const [abnLookupSuccess, setAbnLookupSuccess] = useState(false)
  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const [association, setAssociation] = useState<Association | null>(null)

  const [createAssociation] = useAddAssociationMutation()

  const steps: TStep[] = [
    {
      actionLabel: 'Continue',
      renderComponent: (props: AssociationDetailsFormProps) => (
        <AssociationDetailsForm {...props} />
      ),
      defaultValues: {
        abn: '',
        name: '',
        address: {
          formatted: '',
          line_1: '',
          line_2: '',
          city: '',
          state: '',
          postcode: '',
          country: '',
          location: {
            lat: 0,
            lng: 0,
          },
        },
        logo: '',
        gstRegistered: false,
        agreedToTruth: false,
        agreedToPolicies: false,
      },
      name: 'Association Details',
      validationSchema: object().shape({
        abn: string().required('Association ABN is required'),
        name: string().required('Association name is required'),
        address: object()
          .shape({
            formatted: string(),
            line_1: string(),
            line_2: string(),
            city: string(),
            state: string(),
            postcode: string(),
            country: string(),
            location: object().shape({
              lat: number(),
              lng: number(),
            }),
          })
          .required('Association address is required'),
        gstRegistered: boolean(),
        school: boolean(),
        status: string().oneOf(['Draft']),
        agreedToTruth: boolean().required('You must agree to declaration'),
        agreedToPolicies: boolean().required('You must agree to all policies'),
      }),
    },
    {
      actionLabel: 'Continue',
      renderComponent: (props: AssociationLogoFormProps) => (
        <AssociationLogoForm {...props} />
      ),
      defaultValues: {
        logo: '',
      },
      name: 'Association Logo',
      validationSchema: object().shape({
        logo: mixed().required('Logo is required'),
      }),
    },
    {
      actionLabel: 'Continue',
      renderComponent: (props: any) => <PrimaryContactForm {...props} />,
      defaultValues: {
        primaryContact: {
          name: '',
          email: '',
          phone: '',
        },
      },
      name: 'Primary Contact Details',
      validationSchema: object().shape({
        primaryContact: object().shape({
          name: string().required('Contact name is required'),
          email: string().required('Contact email is required'),
          phone: string().required('Contact phone is required'),
        }),
      }),
    },
    {
      actionLabel: 'Submit for approval',
      renderComponent: (props: any) => <SecondaryContactForm {...props} />,
      defaultValues: {
        secondaryContact: {
          name: '',
          email: '',
          phone: '',
        },
      },
      name: 'Secondary Contact Details',
      validationSchema: object().shape({
        secondaryContact: object().shape({
          name: string().required('Contact name is required'),
          email: string().required('Contact email is required'),
          phone: string().required('Contact phone is required'),
        }),
      }),
    },
  ]

  const activeStep = steps[activeStepIndex]

  const form = useForm<FormInput>({
    shouldUnregister: false,
    defaultValues: steps.reduce((existing, step) => {
      return {
        ...existing,
        ...step.defaultValues,
      }
    }, {}),
    resolver: activeStep?.validationSchema
      ? yupResolver(activeStep.validationSchema)
      : undefined,
    mode: 'onChange',
  })

  const { handleSubmit, trigger, watch } = form

  const abn = watch('abn')

  const handleNext = async () => {
    const isStepValid = await trigger()
    if (isStepValid) {
      setActiveStepIndex(currentActiveStepIndex => currentActiveStepIndex + 1)
    }
  }

  const handleBack = () => {
    if (activeStepIndex > 0) {
      setActiveStepIndex(currentActiveStepIndex => currentActiveStepIndex - 1)
    }
  }

  const onSubmit = async ({ logo, ...data }: FormInput) => {
    if (activeStepIndex === steps.length - 1) {
      try {
        const formData = new FormData()

        data = {
          ...data,
          school: false,
          status: 'Draft',
          subMaster: null,
        }

        formData.append('formData', JSON.stringify(data))
        formData.append('file', logo)
        const createdAssociation = await createAssociation(formData).unwrap()
        setAssociation(createdAssociation)
        handleNext()
      } catch (error: any) {
        console.error({ error: error?.message })
      }
    } else {
      handleNext()
    }
  }

  return (
    <div>
      <Stepper activeStep={activeStepIndex}>
        {steps.map((step: TStep) => {
          return (
            <Step key={step.name}>
              <StepLabel>{step.name}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <div>
        {activeStepIndex === steps.length ? (
          <Success association={association} />
        ) : (
          <Box>
            {activeStep.renderComponent({
              form,
              abnLookupSuccess,
              setAbnLookupComplete,
              setAbnLookupSuccess,
            })}

            <Box
              display="flex"
              justifyContent="end"
              style={{ paddingTop: '1rem' }}
            >
              <Button disabled={activeStepIndex === 0} onClick={handleBack}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (activeStepIndex === 0 && abn && !abnLookupComplete) {
                    form.setError('abn', {
                      type: 'manual',
                      message: 'Press search to lookup your ABN details.',
                    })
                  } else if (
                    activeStepIndex === 0 &&
                    abn &&
                    !abnLookupSuccess
                  ) {
                    form.setError('abn', {
                      type: 'manual',
                      message: 'ABN lookup failed. Check ABN and try again.',
                    })
                  } else {
                    handleSubmit(onSubmit)()
                  }
                }}
              >
                {activeStep.actionLabel}
              </Button>
            </Box>
          </Box>
        )}
      </div>
    </div>
  )
}

export default AssociationRegistrationForm
