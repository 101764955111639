import { Grid, TextField } from '@material-ui/core'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'

import { FormInput } from '.'
import { ErrorMessage } from '../error-message'
import PageHeader from '../page-header'

export type SecondaryContactFormProps = {
  form: UseFormReturn<FormInput, object>
}

export const SecondaryContactForm = ({
  form: {
    formState: { errors },
    register,
  },
}: SecondaryContactFormProps) => {
  return (
    <>
      <PageHeader title="Secondary Contact Details" />
      <Grid direction="column" container spacing={2}>
        <Grid item xs={12} sm={8} md={4}>
          <TextField
            label="Name"
            variant="filled"
            size="small"
            fullWidth
            {...register('secondaryContact.name')}
            required
          />
          {errors.secondaryContact?.name && (
            <ErrorMessage>{errors.secondaryContact.name.message}</ErrorMessage>
          )}
        </Grid>
        <Grid item xs={12} sm={8} md={4}>
          <TextField
            label="Email address"
            variant="filled"
            size="small"
            fullWidth
            {...register('secondaryContact.email')}
            required
          />
          {errors.secondaryContact?.email && (
            <ErrorMessage>
              {errors.secondaryContact?.email.message}
            </ErrorMessage>
          )}
        </Grid>
        <Grid item xs={12} sm={8} md={4}>
          <TextField
            label="Phone number"
            variant="filled"
            size="small"
            fullWidth
            {...register('secondaryContact.phone')}
            required
          />
          {errors.secondaryContact?.phone && (
            <ErrorMessage>{errors.secondaryContact.phone.message}</ErrorMessage>
          )}
        </Grid>
      </Grid>
    </>
  )
}
