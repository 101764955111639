import { Grid, TextField } from '@material-ui/core'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'

import { FormInput } from '.'
import { ErrorMessage } from '../error-message'
import PageHeader from '../page-header'

export type PrimaryContactFormProps = {
  form: UseFormReturn<FormInput, object>
}

export const PrimaryContactForm = ({
  form: {
    formState: { errors },
    register,
  },
}: PrimaryContactFormProps) => {
  return (
    <>
      <PageHeader title="Primary Contact Details" />
      <Grid direction="column" container spacing={2}>
        <Grid item xs={12} sm={8} md={4}>
          <TextField
            label="Name"
            variant="filled"
            size="small"
            fullWidth
            {...register('primaryContact.name')}
            required
          />
          {errors.primaryContact?.name && (
            <ErrorMessage>{errors.primaryContact.name.message}</ErrorMessage>
          )}
        </Grid>
        <Grid item xs={12} sm={8} md={4}>
          <TextField
            label="Email address"
            variant="filled"
            size="small"
            fullWidth
            {...register('primaryContact.email')}
            required
          />
          {errors.primaryContact?.email && (
            <ErrorMessage>{errors.primaryContact?.email.message}</ErrorMessage>
          )}
        </Grid>
        <Grid item xs={12} sm={8} md={4}>
          <TextField
            label="Phone number"
            variant="filled"
            size="small"
            fullWidth
            {...register('primaryContact.phone')}
            required
          />
          {errors.primaryContact?.phone && (
            <ErrorMessage>{errors.primaryContact.phone.message}</ErrorMessage>
          )}
        </Grid>
      </Grid>
    </>
  )
}
