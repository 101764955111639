import { FormControl, Grid } from '@material-ui/core'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'

import { FormInput } from '.'
import { ErrorMessage } from '../error-message'
import PageHeader from '../page-header'

export type AssociationLogoFormProps = {
  form: UseFormReturn<FormInput, object>
}

export const AssociationLogoForm = ({
  form: {
    formState: { errors },
    setValue,
    watch,
  },
}: AssociationLogoFormProps) => {
  const handleFileChange = (e: any) => {
    setValue('logo', e.target.files[0])
  }

  const logo = watch('logo')

  console.log(logo)

  return (
    <>
      <PageHeader title="Association Logo" />
      <Grid direction="row" container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl variant="outlined" sx={{ margin: 'auto' }}>
            <label
              htmlFor="fileupload"
              style={{
                fontWeight: 'bold',
              }}
            >
              Upload an image
              <input
                id="fileupload"
                onChange={handleFileChange}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                style={{
                  display: 'block',
                  visibility: 'hidden',
                }}
              />
              <span
                style={{
                  fontWeight: 'normal',
                  fontSize: '16px',
                }}
              >
                PNG and JPG file format only
              </span>
              <CloudUploadIcon
                style={{
                  display: 'block',
                  fontSize: '70px',
                  margin: '50px auto',
                }}
              />
            </label>
          </FormControl>
          {errors.logo && <ErrorMessage>{errors.logo.message}</ErrorMessage>}
        </Grid>
        {logo && (
          <Grid item xs={12} sm={6} md={6}>
            <img
              alt="Association Logo"
              src={URL.createObjectURL(logo)}
              width="auto"
              height="auto"
              style={{ maxWidth: '250px', maxHeight: '250px' }}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}
