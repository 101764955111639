import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Container } from '@material-ui/core'
import { PageProps } from 'gatsby'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import MobileNavbar from '../../components/navbar/mobile'
import Navbar from '../../components/navbar'
import AssociationRegistrationForm from '../../components/register-association'

const RegisterAssociationPage = (props: PageProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <Container
        sx={{
          marginTop: '40px',
        }}
      >
        <AssociationRegistrationForm />
      </Container>
    </>
  )
}

export default withAuthenticationRequired(RegisterAssociationPage)
