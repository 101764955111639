import api from '..'
import { SubMaster } from './sub-masters'

export interface Address {
  formatted: string
  line_1: string
  line_2: string
  city: string
  state: string
  postcode: string
  country: string
  location: {
    lat: number
    lng: number
  }
}

export type AssociationStatus = 'Draft' | 'Approved' | 'Rejected' | 'Disabled'

export interface Association {
  _id: string
  ttr_id: string
  name: string
  subMaster: SubMaster
  primaryContact: {
    name: string
    email: string
    phone: string
  }
  secondaryContact: {
    name: string
    email: string
    phone: string
  }
  address: Address
  bank: {
    accountName: string
    bsb: string
    accNo: string
  }
  abn: number
  posId: string
  logo: string
  school: string
  gstRegistered: string
  status: AssociationStatus
  stripeAccountId: string
  stripeChargesEnabled: boolean
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
}

type AssociationsResponse = Association[]

export const associationsApi = api.injectEndpoints({
  endpoints: build => ({
    getAssociations: build.query<AssociationsResponse, QueryParams>({
      query: params => {
        const { offset, limit, search } = params
        return {
          url: 'associations/',
          params: {
            offset,
            limit,
            search,
          },
        }
      },
      providesTags: ['associations'],
    }),
    getMobileAssociations: build.query<AssociationsResponse, QueryParams>({
      query: params => {
        const { offset, limit, search } = params
        return {
          url: 'mobile/associations/',
          params: {
            offset,
            limit,
            search,
          },
        }
      },
      providesTags: ['associations'],
    }),
    getAssociation: build.query<Association, string>({
      query: id => `associations/${id}`,
      providesTags: ['association'],
    }),
    addAssociation: build.mutation({
      query: body => ({
        url: 'associations?addToAuth0=true',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['associations'],
    }),
    abnLookup: build.query<any, string>({
      query: abn => `associations/abn-lookup/${abn}`,
      providesTags: ['abn-lookup'],
    }),
  }),
})

export const {
  useAddAssociationMutation,
  useGetAssociationsQuery,
  useGetAssociationQuery,
  useLazyAbnLookupQuery,
  useLazyGetAssociationsQuery,
  useLazyGetMobileAssociationsQuery,
} = associationsApi
