import { Box, styled, Typography } from '@material-ui/core'
import React from 'react'

import PageHeader from '../page-header'
import { Association } from '../../services/api/endpoints/associations'

const SuccessContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.up(801)]: {
        maxWidth: '60%',
      },
      [theme.breakpoints.down(800)]: {
        margin: 'auto',
        maxWidth: '100%',
      },
    }
  }}
  margin: auto;
  padding: 2rem;
`

const SuccessTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        fontSize: '1.3rem',
      },
      [theme.breakpoints.down(1200)]: {
        fontSize: '1.1rem',
      },
      [theme.breakpoints.down(800)]: {
        fontSize: '0.9rem',
      },
    }
  }}

  font-size: 1.5rem;
  color: #3c3835;
  margin-bottom: 2rem;
`

type SuccessProps = {
  association: Association | null
}

export const Success = ({ association }: SuccessProps) => {
  return (
    <SuccessContainer>
      <PageHeader title="Success Screen" />
      <SuccessTypography>
        Thank you for supplying the required information on behalf of{' '}
        {association?.name || 'your association'}.
      </SuccessTypography>
      <SuccessTypography>
        We are currently verifying the information you provided and will be in
        contact in the next 48 hours.
      </SuccessTypography>
      <SuccessTypography>
        We look forward to you being part of the team.
      </SuccessTypography>
    </SuccessContainer>
  )
}
